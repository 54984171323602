<template>
  <div>
    <teams-board :is-board-starred-only="true" class="mt-4" />
  </div>
</template>

<script>
import TeamsBoard from '@/views/TeamsBoard.vue';

export default {
  components: {
    TeamsBoard
  }
};
</script>
