<template>
  <div>
    <modal-freetrial />
    <div v-if="loading" />
    <base-empty
      v-else-if="!(boards.length || boardsYour.length) && !q.length"
      :image="require('@/assets/image/BoardEmpty.svg')"
      :title="$t('emptyBoard.title')"
      :description="$t('emptyBoard.description')"
    >
      <base-button
        v-if="!isUserViewer"
        slot="action"
        class="btn--empty"
        full
        @click="openCreateBoard"
      >
        {{ $t('team.workspaceBoard.create') }}
      </base-button>
    </base-empty>
    <div v-else class="px-2">
      <div class="flex items-centers py-4 pl-2 pr-4">
        <div class="text-xl pr-4 pt-1">
          {{ $t('team.workspaceBoard.workspace') }}
        </div>
        <div class="relative">
          <base-input-text
            v-model="q"
            :placeholder="$t('team.workspaceBoard.placeholder')"
            icon-fa="search"
            type="text"
            class="input-pr-8"
            style="background: #F2F3F4;"
            inner-class="base-input--gray"
          />
          <div v-if="q" class="icon--clear" @click="q = ''">
            <base-icon size="14" name="close">
              <icon-close />
            </base-icon>
          </div>
        </div>
        <div class="flex-1 text-right">
          <base-button
            v-if="!isUserViewer"
            color="brand"
            wide="px-4"
            @click="openCreateBoard"
          >
            <fa-icon icon="plus" class="mr-2" />
            {{ $t('team.workspaceBoard.create') }}
          </base-button>
        </div>
      </div>
      <div v-if="isBoardStarredOnly">
        <!-- star -->
        <div v-if="boardsStarred.length" class="mb-4">
          <div class="flex items-center px-4">
            <div class="pr-2">
              <base-icon size="17" class="align-text-bottom">
                <icon-star-border />
              </base-icon>
            </div>
            <div class="text-lg pr-2">
              {{ $t('team.workspaceBoard.starred') }}
            </div>
            <div class="text-sm text-gray-500">
              {{ boardsStarred.length }} {{ $t('team.workspaceBoard.board') }}
            </div>
          </div>

          <board-list
            :boards="boardsStarred"
            :is-callback-click="true"
            @click="boardClick"
          />
        </div>
        <div v-else>
          <div class="flex justify-center">
            <div>
              <div class="mt-32 flex justify-center">
                <IconEmptyStaredBoard />
              </div>
              <p
                class="text-xl font-medium text-center"
                style="margin-top:15px"
              >
                {{ $t('board.stared.empty.title') }}
              </p>
              <div class="mt-3 text-center">
                <p class="font-light max-w-md text-gray-caption text-sm">
                  {{ $t('board.stared.empty.description') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <!-- star -->
        <div v-if="boardsStarred.length" class="mb-4">
          <div class="flex items-center px-4">
            <div class="pr-2">
              <base-icon size="17" class="align-text-bottom">
                <icon-star-border />
              </base-icon>
            </div>
            <div class="text-lg pr-2">
              {{ $t('team.workspaceBoard.starred') }}
            </div>
            <div class="text-sm text-gray-500">
              {{ boardsStarred.length }} {{ $t('team.workspaceBoard.board') }}
            </div>
            <div
              v-if="boardsStarred.length > 1"
              style="color:#2F80ED"
              class="text-sm ml-2 cursor-pointer"
              @click="toStarredBoard"
            >
              {{ $t('viewMore') }}
            </div>
          </div>
          <board-list
            :boards="boardsStarred"
            :is-callback-click="true"
            is-one-row
            @click="boardClick"
          />
        </div>

        <!-- your -->
        <div v-if="boardsYour.length" class="mb-4">
          <div class="flex items-center px-4">
            <div class="pr-2">
              <base-icon size="17" class="align-text-bottom">
                <icon-boards-border />
              </base-icon>
            </div>
            <div class="text-lg pr-2">{{ $t('team.workspaceBoard.your') }}</div>
            <div class="text-sm text-gray-500">
              {{ boardsYour.length }} {{ $t('team.workspaceBoard.board') }}
            </div>
          </div>
          <board-list
            :boards="boardsYour"
            :is-callback-click="true"
            @click="boardClick"
          />
        </div>

        <!-- boards -->
        <div v-if="boards.length" class="mb-4">
          <div class="flex items-center px-4">
            <div class="pr-2">
              <base-icon size="17" class="align-text-bottom">
                <icon-boards-border />
              </base-icon>
            </div>
            <div class="text-lg pr-2">
              {{ $t('team.workspaceBoard.workspace') }}
            </div>
            <div class="text-sm text-gray-500">
              {{ boards.length }} {{ $t('team.workspaceBoard.board') }}
            </div>
          </div>
          <board-list
            :boards="boards"
            :add-on="!isUserViewer ? true : false"
            :is-callback-click="true"
            @click="boardClick"
            @addClick="openCreateBoard"
          />
        </div>
      </div>
    </div>

    <modal
      ref="board-join"
      name="board-join"
      :width="676"
      adaptive
      height="auto"
      scrollable
    >
      <base-empty :image="require('@/assets/image/BoardNotJoin.svg')">
        <div
          class="absolute top-0 right-0 mt-6 mr-6 cursor-pointer"
          @click="$modal.hide('board-join')"
        >
          <base-icon color="#333333">
            <icon-close />
          </base-icon>
        </div>
        <div class="pb-4 text-center text-2xl text-black font-medium">
          {{ $t('team.workspaceBoard.notMember') }}
        </div>
        <div class="py-4">
          <base-button
            slot="action"
            class="px-6 text-base"
            @click="boardJoinClick"
          >
            {{ $t('team.workspaceBoard.join') }}
          </base-button>
        </div>
      </base-empty>
    </modal>
  </div>
</template>

<script>
import BoardList from '@/components/board/board-list';
import IconClose from '@/components/icons/IconClose';
import IconStarBorder from '@/components/icons/IconStarBorder';
import IconBoardsBorder from '@/components/icons/IconBoardsBorder';
import modalFreetrial from '@/components/modal/modal-freetrial';
import { mapGetters } from 'vuex';
import IconEmptyStaredBoard from '@/components/icons/IconEmptyStaredBoard.vue';

import { boardsApi } from '@/services/apis';

export default {
  props: {
    isBoardStarredOnly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BoardList,
    IconClose,
    IconStarBorder,
    IconBoardsBorder,
    modalFreetrial,
    IconEmptyStaredBoard
  },
  name: 'Teams-Board',
  data() {
    return {
      loading: false,
      joinBoard: {},
      q: ''
    };
  },
  watch: {
    '$route.params.id'() {
      this.q = '';
      this.fetch();
    }
  },
  async mounted() {
    this.fetch();
    // if (this.getUseFreePlan) {
    //   this.$modal.show('alert-freetrial');
    //   this.$store.commit('subscription/SET_USE_FREE_PLAN', false);
    // }
  },
  computed: {
    // ...mapGetters('subscription', ['getUseFreePlan']),
    boards() {
      const boardsYourIds = this.boardsYour.map(e => e.id);
      if (this.q)
        return this.$store.getters['teams/getBoards'].filter(
          f =>
            !boardsYourIds.includes(f.id) &&
            f.name.toLowerCase().search(this.q.toLowerCase()) >= 0
        );
      else
        return this.$store.getters['teams/getBoards'].filter(
          f => !boardsYourIds.includes(f.id)
        );
    },
    boardsStarred() {
      if (this.q)
        return this.$store.getters['teams/getBoardsStarred'].filter(
          f => f.name.toLowerCase().search(this.q.toLowerCase()) >= 0
        );
      else return this.$store.getters['teams/getBoardsStarred'];
    },
    boardsYour() {
      if (this.q)
        return this.$store.getters['teams/getBoardsYour'].filter(
          f => f.name.toLowerCase().search(this.q.toLowerCase()) >= 0
        );
      else return this.$store.getters['teams/getBoardsYour'];
    },
    isUserViewer() {
      return this.$store.getters['teams/isUserViewer'];
    },
    profile() {
      return this.$store.getters['auth/getUser'];
    }
  },
  methods: {
    async fetch() {
      await this.$store.dispatch('teams/fetchBoards', this.$route.params.id);
    },
    openCreateBoard() {
      this.$modal.show('create-board', {
        teamId: parseInt(this.$route.params.id)
      });
    },
    async boardClick(board) {
      if (!board.isMemberJoined) {
        this.joinBoard = board;
        // this.$modal.show('board-join');
        await this.boardJoinClick();
      } else {
        this.$router.push({
          name: 'Boards-Id',
          params: {
            id: board.id
          }
        });
      }
    },
    async boardJoinClick() {
      const self = this;
      await boardsApi
        .joinBoard(self.joinBoard.id)
        .then(response => {
          self.$router.push({
            name: 'Boards-Id',
            params: {
              id: self.joinBoard.id
            }
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    toStarredBoard() {
      this.$router.push({ name: 'Workspace-Star-Board' });
    }
  }
};
</script>

<style scoped>
.icon--clear {
  @apply px-3 absolute right-0 inset-y-0 cursor-pointer z-10 inline-flex place-items-center;
}
</style>
